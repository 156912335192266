<template>
  <validation-provider
    :vid="name"
    :name="label ? label : ' '"
    :rules="rules"
    :disabled="disabled"
    v-slot="{ errors, valid, validated }"
  >
    <b-form-group :label="label && !showImportExport ? `${label} ${required ? '*' : ''}` : null">
      <span v-if="showImportExport" class="float-left mb-2">
        {{ label ? `${label} ${required ? '*' : ''}` : null }}
      </span>
      <div v-if="showImportExport" class="position-absolute" style="right: 0; top: 0">
        <soi-icon-button
          class="float-right text-success"
          :id="`popover-target-${name}-3`"
          icon="file-export"
          size="lg"
          :disabled="disabled"
          @click="$emit('import', { field: name })"
          variant="link"
        />
        <soi-icon-button
          v-if="showImportExport"
          class="float-right text-primary"
          :id="`popover-target-${name}-2`"
          icon="file-import"
          size="lg"
          :disabled="disabled"
          @click="$emit('export', { field: name })"
          variant="link"
        />
      </div>
      <b-form-textarea
        :style="{overflow: overflow}"
        :name="name"
        v-model="text"
        :placeholder="placeholder"
        :maxlength="maxlength"
        :rows="rows"
        :max-rows="maxRows"
        :disabled="disabled"
        :state="(rules.length && validated) ? valid : null"
        @input="$emit('change', { value: $event, field: name });$emit('input', $event)"
        :spellcheck="spellCheck"
        no-resize
        trim
      />
      <p v-if="text && text.length >= maxlength" class="text-danger small mt-2">
        O texto atingiu o tamanho máximo permitido de {{ maxlength }} caracteres.
      </p>
      <b-form-invalid-feedback class="error-container">
        {{ errors[0] }}
      </b-form-invalid-feedback>
      <b-popover v-if="!!hintTextButton"
        :target="`popover-target-${name}-1`" triggers="hover" placement="top">
        {{hintTextButton}}
      </b-popover>
      <b-popover v-if="!!hintTextButton2"
        :target="`popover-target-${name}-2`" triggers="hover" placement="top">
        {{hintTextButton2}}
      </b-popover>
      <b-popover v-if="!!hintTextButton3"
        :target="`popover-target-${name}-3`" triggers="hover" placement="top">
        {{hintTextButton3}}
      </b-popover>
    </b-form-group>
  </validation-provider>
</template>

<script lang="ts">
import {
  Component,
  Vue,
  Prop,
  Watch,
} from 'vue-property-decorator';
import ComponenteBase from '../ComponenteBase.vue';

@Component
class Textarea extends ComponenteBase {
  @Prop({ type: String, default: '' })
  public label!: string;

  @Prop({ type: String, default: '' })
  public name!: string;

  @Prop({ type: String, default: '' })
  private value!: string;

  @Prop({ type: String, default: '' })
  public placeholder!: string;

  @Prop({ type: Boolean, default: false })
  public disabled!: boolean;

  @Prop({ type: Number, default: 3 })
  public rows!: number;

  @Prop({ type: Number, default: 6 })
  public maxRows!: number;

  @Prop({ type: Number, default: 255 })
  public maxlength!: number;

  @Prop({ type: Array, default: () => [] })
  private validation!: string[];

  @Prop({ type: String, default: '' })
  public hintTextButton!: string[];

  @Prop({ type: String, default: '' })
  public hintTextButton2!: string[];

  @Prop({ type: String, default: '' })
  public hintTextButton3!: string[];

  @Prop({ type: Boolean, default: true })
  public spellCheck!: boolean;

  @Prop({ type: Boolean, default: false })
  public showImportExport!: boolean;

  @Prop({ type: String, default: 'hidden' })
  public overflow!: string;

  public text: string;

  get rules() {
    return this.validation.join('|');
  }

  get required() {
    return this.validation.find((rule) => rule === 'required');
  }

  constructor() {
    super();
    this.text = this.value;
  }

  @Watch('value')
  private changeValue() {
    Vue.nextTick(() => {
      this.text = this.value;
    });
  }
}

export default Textarea;
</script>

<style lang="scss" scoped>
.form-group {
  position: relative;
  margin-bottom: 25px;
}

.error-container {
  position: absolute;
  top: 100%;
}
</style>
