<template>
  <h1 class="title">
    {{ title }}
  </h1>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import ComponenteBase from './ComponenteBase.vue';

@Component
export default class Title extends ComponenteBase {
  @Prop({ type: String, default: '' })
  private title!: string;
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 2em;
  margin-bottom: 15px;
}
</style>
