<template>
  <router-link
    :class="{
      btn: variant !== 'link',
      [`btn-${variant}`]: true,
    }"
    :to="{ name: routeName, params: routeParams, hash: hashRecebida }"
    :disabled="disabled"
  >
    <span class="content">
      <fa-icon v-if="!!icon" :icon="icon" size="sm" />
      <span :class="{ 'pl-2': !!icon }">{{ label }}</span>
    </span>
  </router-link>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import ComponenteBase from '../ComponenteBase.vue';

@Component
export default class Link extends ComponenteBase {
  @Prop({ type: String, default: '' })
  private label!: string;

  @Prop({ type: String, default: '' })
  private icon!: string;

  @Prop({ type: String, default: 'primary' })
  private variant!: string;

  @Prop({ type: Boolean, default: false })
  private disabled!: boolean;

  @Prop({ type: String, default: '' })
  private routeName!: string;

  @Prop({ type: Object, default: () => {} })
  private routeParams!: Object;

  @Prop({ type: String, default: '' })
  private hash!: string;

  get hashRecebida(): string {
    return this.hash ? `#${this.hash}` : '';
  }
}
</script>

<style lang="scss" scoped>
.btn {
  min-width: 120px;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
