<template>
  <div>
    <div class="table-header">
      <b-form-input
        v-show="!!filterable"
        class="search-input mb-3"
        v-model="search"
        placeholder="Procure por algum registro..."
        debounce="500"
        trim
      />

      <soi-select
        v-show="!!showPerPage"
        class="perpage-select"
        placeholder=""
        :allow-empty="false"
        :selected="perPage"
        :options="perPageOptions"
        @change="changePerPage"
      />
    </div>

    <div class="table-responsive">
      <table class="table table-striped table-bordered table-hover">
        <thead>
          <template v-if="!!fields.length">
            <tr>
              <th v-if="selectable" class="text-center check-column">
                <soi-checkbox
                  class="mb-0 pl-2"
                  :value="allRowsIsChecked"
                  :disabled="!selectMultiple"
                  @change="toggleCheckAll"
                />
              </th>
              <th v-for="(field, index) in fields" :key="index" :class="{ [field.class]: true }">
                {{ field.label }}
              </th>
            </tr>
          </template>
        </thead>
        <tbody v-if="!!rows.length">
          <tr v-for="(row, index) in rows" :key="index" @click="toggleItemByLine(row, $event)">
            <td v-if="selectable" class="text-center check-column" :key="'check-' + index">
              <soi-checkbox
                class="mb-0 pl-2"
                :value="row.checked"
                @change="toggleItem(row, $event.value)"
              />
            </td>
            <template v-for="(field) in fields">
              <td
                v-if="field.isTemplate"
                :key="index + field.key"
                :class="{ [field.class]: true }"
              >
                <slot :name="field.key" :item="row" />
              </td>
              <td v-else :key="index + field.key" :class="{ [field.class]: true }">
                {{ row[field.key] }}
              </td>
            </template>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td :colspan="fields.length + (this.selectable ? 1 : 0)" align="center">
              Nenhum registro encontrado!
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="table-footer">
      <span>{{showingRange}}</span>

      <b-pagination
        align="right"
        v-model="currentPage"
        :per-page="perPage"
        :total-rows="totalRows"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';
import IDictionary from '../models/Interfaces/IDictionary';
import IInputChangePayload from '../models/Interfaces/IInputChangePayload';
import ComponenteBase from './ComponenteBase.vue';

@Component
export default class Table extends ComponenteBase {
  @Prop({ type: Array, default: () => [] })
  private fields!: Array<string>;

  @Prop({ type: Array, default: () => [] })
  private items!: Array<any>;

  @Prop({ type: Boolean, default: true })
  private filterable!: boolean;

  @Prop({ type: Boolean, default: true })
  private showPerPage!: boolean;

  @Prop({ type: Number, default: 10 })
  private quantityPerPage!: number;

  @Prop({ type: Boolean, default: false })
  private selectable!: boolean;

  @Prop({ type: Boolean, default: false })
  private selectMultiple!: boolean;

  private search: string;

  private perPage: number;

  private currentPage: number;

  private perPageOptions: Array<IDictionary>;

  private filtredItems: Array<any>;

  get totalRows() {
    return this.search.length ? this.filtredItems.length : this.items.length;
  }

  get showingRange() {
    const start = this.perPage * (this.currentPage - 1) + 1;
    const end = start + this.rows.length - 1;

    return `Mostrando de ${start} até ${end} de ${this.totalRows} registros`;
  }

  get rows() {
    let rows: Array<any> = [];

    if (this.items) {
      const start = this.perPage * (this.currentPage - 1);

      if (this.filterable && this.search) {
        const data = this.items.filter((item) => {
          // eslint-disable-next-line no-restricted-syntax
          for (const prop in item) {
            if (String(item[prop]).toLowerCase().includes(this.search.toLowerCase())) {
              return true;
            }
          }
          return false;
        });

        this.filtredItems = [...data];
        rows = data.splice(start, start + this.perPage);
      } else {
        this.filtredItems = [];
        rows = this.items.slice(start, start + this.perPage);
      }
    }

    return rows;
  }

  get allRowsIsChecked() {
    return this.selectMultiple && this.rows.length && this.rows.every((item) => !!item.checked);
  }

  get selectedItems() {
    return this.items.filter((item) => item.checked);
  }

  constructor() {
    super();
    this.search = '';
    this.perPage = this.quantityPerPage;
    this.currentPage = 1;

    this.perPageOptions = [
      { key: '5', value: '5' },
      { key: '10', value: '10' },
      { key: '25', value: '25' },
      { key: '50', value: '50' },
      { key: '100', value: '100' },
    ];

    this.filtredItems = [];
  }

  private changePerPage(payload: IInputChangePayload) {
    const perPage = payload.value;

    Vue.nextTick(() => {
      this.perPage = Number(perPage);
    });
  }

  public clearSelection() {
    this.items.forEach((value, key) => {
      this.$set(this.items, key, { ...value, checked: false });
    });
  }

  private toggleItemByLine(row: any, event: PointerEvent) {
    event.preventDefault();
    if (event.type === 'click') {
      const index = this.items.findIndex(({ id }) => id === row.id);

      const item = this.items[index]; item.checked = !item.checked;

      if (index >= 0) {
        if (!this.selectMultiple) {
          this.items.forEach((value, key) => {
            this.$set(this.items, key, { ...value, checked: false });
          });
        }
        this.$set(this.items, index, item);
      }
    }
  }

  private toggleCheckAll(payload: IInputChangePayload) {
    if (!this.selectMultiple) return;

    if (payload.value) {
      this.rows.forEach((row) => {
        const index = this.items.findIndex((item) => item.id === row.id);
        if (index >= 0) {
          const item = this.items[index];
          item.checked = true;

          this.$set(this.items, index, item);
        }
      });
    } else {
      this.rows.forEach((row) => {
        const index = this.items.findIndex((item) => item.id === row.id);
        if (index >= 0) {
          const item = this.items[index];
          item.checked = false;

          this.$set(this.items, index, item);
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.table-header {
  margin-top: 10px;

  .search-input {
    float: left;
    width: calc(100% - 110px);
  }

  .perpage-select {
    float: right;
    width: 90px;
  }
}

.table-responsive {
  max-width: 100%;
  padding: 0 1px;
  margin-bottom: 1rem;

  .table {
    margin: 0;

    .check-column {
      width: 10px;
    }
  }

  .table-bordered {
    border: 1px solid #dee2e6 !important;
  }
}

.table-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap-reverse;

  & > span {
    font-size: .875em;
  }
}
</style>
