
<script lang="ts">
import { Vue } from 'vue-property-decorator';
import TipoDeAction from '../constants/enums/TipoDeAction';
import DateHelper from '../helpers/DateHelper';
import Permissions from '../helpers/Permissions';

export default class ComponenteBase extends Vue {
  public readonly MENSAGEM_NAO_INFORMADO: string = 'Não Informado';

  public readonly MENSAGEM_NAO_INFORMADA: string = 'Não Informada';

  public readonly MASCARA_DE_DATA: string = '_____/_____/________';

  get permissions(): typeof Permissions {
    return Permissions;
  }

  get tipoDeAction(): typeof TipoDeAction {
    return TipoDeAction;
  }

  public distinctBy(array: Array<any>, key: string) {
    return [...new Map(array
      .map((item) => ([item[key], item]))).values()];
  }

  public groupBy<T, Tkey extends keyof T>(array: Array<T>, key: Tkey) : T[][] {
    return Object.values(array.reduce((acumulador, valorAtual) => {
      (acumulador[valorAtual[key]] = acumulador[valorAtual[key]] || []) // eslint-disable-line
        .push(valorAtual);
      return acumulador;
    }, {} as any));
  }

  public stringNulaOuVazia(palavra?: string) : boolean {
    return palavra === '' || palavra === null || palavra === 'null' || palavra === undefined;
  }

  public formataMoeda(valor: number): string {
    return valor?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
  }

  public formataData(str: string) {
    if (str === '0001-01-01T00:00:00') {
      return 'Não Informada';
    }

    return DateHelper.formatBR(str);
  }

  public formataNumeroTelefone(numero: string): string {
    const regex = /^(\d{2})(\d{5})(\d{4})$/;
    return numero.replace(regex, '($1) $2-$3');
  }

  /**
 * Procura por âncoras na URL e move a tela para o elemento HTML em que ela pertence.
 * É necessário ter um elemento(div) com o ref da ancora.
 */
  public verificarAncoras() {
    const { hash } = this.$route;

    if (hash) {
      const ancora = hash.replace('#', '');
      const componente = (this.$refs[`${ancora}`] as HTMLElement);
      Vue.nextTick(() => {
        if (componente) {
          componente.scrollIntoView({ behavior: 'smooth' });
        }
      });
    }
  }
}
</script>
